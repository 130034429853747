import Vue from "vue";
import moment from "moment";
import axios from "axios";

export default {};

export const utils = {
  install(Vue) {
    Vue.prototype.$utils = {
      separateThousands: function (text) {
        var result = "";
        var minus = false;
        if ((text + "").charAt(0) === "-") {
          minus = true;
        }
        var newText = (text + "").replace(/,/g, "").replace(/-/g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 3 === 0 && i !== newText.length) {
            result = "," + result;
          }
        }
        if (minus) {
          result = "-" + result;
        }
        return result;
      },
      toNumber: function (text) {
        return text * 1;
      },
      toText: function (number) {
        return number + "";
      },
      lpad: function (text, padString, length) {
        // 숫자 앞에 0 붙이기
        let str = "" + text;
        while (str.length < length) {
          str = padString + str;
        }
        return str;
      },
      itemStyle(condition, name, color) {
        if (condition) {
          return name + ":" + color;
        } else {
          return "";
        }
      },
      textValue(text) {
        if (text != null) return text;
        else return "";
      },
      numberValue(number) {
        if (number != null) return number;
        else return 0;
      },
      getShortened(text, maxLength) {
        let result = text + "";
        if (text.length > maxLength) {
          result = text.slice(0, maxLength) + "..";
        }
        return result;
      },
      isEmpty(text) {
        if (
          text === undefined ||
          typeof text === "undefined" ||
          text === null ||
          text.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      isSameDate(date1, date2) {
        if (Number(date1) === Number(date2)) {
          return true;
        } else {
          return false;
        }
      },
      isSameDay(date1, date2) {
        let result = false;
        if (date1.getFullYear() === date2.getFullYear()) {
          if (date1.getMonth() === date2.getMonth()) {
            if (date1.getDate() === date2.getDate()) {
              result = true;
            }
          }
        }
        return result;
      },
      getPhoneText: function (text) {
        var result = "";
        var newText = (text + "").replace(/-/g, "");
        newText = newText.replace(/ /g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 4 === 0 && i != newText.length) {
            result = "-" + result;
          }
        }
        return result;
      },
      getTelPhoneText: function (text) {
        let phoneNumber = text + "";
        let phone1 = "";
        let phone2 = "";
        let phone3 = "";
        let result = "";
        if (phoneNumber.startsWith("02")) {
          // phone1: 앞 2자리, phone3: 뒤 4자리, phone2: 나머지
          phone1 = phoneNumber.slice(0, 2);
          phone2 = phoneNumber.slice(2, phoneNumber.length - 4);
          phone3 = phoneNumber.slice(phoneNumber.length - 4);
        } else {
          let areaCodes = [
            "031",
            "032",
            "033",
            "041",
            "042",
            "043",
            "044",
            "051",
            "052",
            "053",
            "054",
            "055",
            "061",
            "062",
            "063",
            "064",
          ];
          let areaCode = phoneNumber.slice(0, 3);
          if (areaCodes.includes(areaCode)) {
            // phone1: 앞 3자리, phone3: 뒤 4자리, phone2: 나머지
            phone1 = phoneNumber.slice(0, 3);
            phone2 = phoneNumber.slice(3, phoneNumber.length - 4);
            phone3 = phoneNumber.slice(phoneNumber.length - 4);
          } else {
            // 4자리씩 구분
            let phoneNumbers = this.getPhoneText(phoneNumber).split("-");
            if (phoneNumbers.length > 2) {
              phone1 = phoneNumbers[0];
              phone2 = phoneNumbers[1];
              phone3 = phoneNumbers[2];
            }
          }
        }
        if (phone1.length > 0 && phone2.length > 0 && phone3.length > 0) {
          result = phone1 + "-" + phone2 + "-" + phone3;
        } else {
          result = this.getPhoneText(phoneNumber);
        }
        return result;
      },
      getBusinessNumberText: function (text) {
        var result = "";
        var newText = (text + "").replace(/-/g, "");
        newText = newText.replace(/ /g, "");
        if (newText.length >= 10) {
          result = `${newText.slice(0, 3)}-${newText.slice(
            3,
            5
          )}-${newText.slice(5)}`;
        } else {
          result = newText;
        }
        return result;
      },
      onInput(text, object, regExp, maxLength, nextFocus, onMessage) {
        var result = text + "";
        if (regExp != null) {
          var matched = result.match(regExp);
          if (matched != null) {
            let matchedText = "";
            for (let i = 0; i < matched.length; i++) {
              matchedText += matched[i];
            }
            result = matchedText;
          } else {
            result = "";
          }
          if (result.length != text.length) {
            if (onMessage != null) {
              onMessage(text);
            }
          }
        }
        if (maxLength != null) {
          result = result.substring(0, maxLength);
        }
        object.lazyValue = result;
        if (nextFocus != null) {
          if (result.length === maxLength) {
            nextFocus.focus();
          }
        }
        return result;
      },
      getSecondsDiff(startDate, endDate) {
        const msInSecond = 1000;
        return Math.round(Math.abs(endDate - startDate) / msInSecond);
      },
      getResidentNumber(text) {
        let numbers = text;
        if (numbers.length >= 8) {
          numbers = text.substring(0, 8);
          numbers += "******";
        }
        console.log("utils getResidentNumber result", text);
        return numbers;
      },
      getFullResidentNumber(text) {
        var numbers = (text + "").replace(/-/g, "");
        if (numbers.length >= 13) {
          numbers = text.substring(0, 6) + "-" + text.substring(6);
        }
        console.log("utils getResidentNumber result", text);
        return numbers;
      },
      getWeekday(date) {
        let weekday = date.getDay();
        if (weekday === 0) {
          return "일";
        } else if (weekday === 1) {
          return "월";
        } else if (weekday === 2) {
          return "화";
        } else if (weekday === 3) {
          return "수";
        } else if (weekday === 4) {
          return "목";
        } else if (weekday === 5) {
          return "금";
        } else if (weekday === 6) {
          return "토";
        }
        return 0;
      },
      getSmsLength: function (str) {
        var len = 0;
        if (str != null && str != undefined) {
          for (var i = 0; i < str.length; i++) {
            var c = str.charCodeAt(i);
            if (
              c > 128 ||
              c === 0x22 ||
              c === 0x27 ||
              c === 0x2f ||
              c === 0x5c ||
              c === 0x7c
            ) {
              // ", ', /, \, |
              len += 2;
            } else if (c === 0x0d) {
              // 0x0A 에서 1카운트 0x0D 는 무시
              len += 0;
            } else {
              len += 1;
            }
          }
        }
        return len;
      },
      getMonthDate(monthAgo, day) {
        const today = new Date();
        let monthIndex = today.getMonth() - monthAgo;
        if (day === 0) {
          monthIndex += 1;
        }
        return moment(new Date(today.getFullYear(), monthIndex, day)).format(
          "YYYY-MM-DD"
        );
      },
      async createShortUrl(url, key = "") {
        let baseUrl = "https://flydoc.kr";
        let shortUrl = "";
        try {
          let apiUrl = baseUrl + "/api/set";
          // let referer = "https://hospital.flydoctor.kr";
          let headers = {
            "Content-Type": "application/json",
            "X-URL": url,
            "X-CLIENT-TOKEN": "0uU4t0Jzritr7iz9zCnH",
          };
          if (key !== "") {
            headers["X-KEY"] = key;
          }
          await axios
            .get(apiUrl, { headers: headers })
            .then((response) => (shortUrl = response.data))
            .catch(function (error) {
              console.log(error);
            });
        } catch (e) {
          console.log(e);
        }
        return shortUrl;
      },
      getQRUrl(url) {
        let urlData = url.split("/", 4);
        return "https://" + urlData[2] + "/qr/" + urlData[3];
      },
      async createUrl(item) {
        let url = "https://asia-northeast3-doctor365-9a68d.cloudfunctions.net/createAppLink";
        let imageUrl = "";
        if (this.isEmpty(item.hospitalImages) === false) {
          if (item.hospitalImages.length > 0) {
            imageUrl = item.hospitalImages[0];
          }
        }
        // let onCreateUrl = this.onCreateUrl;
        let data = {
          "title": item.name + " '나의진료'어플 추천해요!",
          "description": item.name + " '나의진료' 어플로 간편하게 예약+수납+제증명+혜택 이용하세요!",
          "imageUrl": imageUrl,
          "prefix": "https://link.flydoctor.kr/",
          "params": {
            "hospitalId": item.id,
            // "userId": "testUserId",
            // "hospitalNoticeId": "testNoti"
          }
        };
        let link;
        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(async function(response) {
            // response.data
            link = response.data;
            // console.log("* link:", link);
            // onCreateUrl(response.data.shortLink);
            return response.data;
          })
          .catch(function(error) {
            console.log(error);
          });
        return link;
      },
      getNoDataText() {
        return "데이터가 없습니다.";
      },
      writeLog(message) {
        console.log(message, "(", moment().format("YYYY-MM-DD HH:mm:ss"), ")");
      },
    };
  },
};

Vue.use(utils);
